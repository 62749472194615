<template>
  <div>
    <PageIntroduce :title="'隐私声明管理'" :desc="'企业需依法收集和使用个人信息，在业务中对个人信息的采集、处理、分享等各项行为进行明确声明，包括Web网站，移动客户端，微信小程序等。'" :tips="'中国个人信息保护法 第六条 第十条 第十三条'"></PageIntroduce>
    <b-card>
      <div class="filter-wrap flex-between">
        <div class="flex-start search-filter-wrap">
          <el-input
              v-model="dataForm.searchStr"
              width="180"
              placeholder="请输入内容"
              clearable
              @clear="handleFilter"
          />
          <el-button
              size="small"
              type="primary"
              icon="el-icon-search"
              @click="handleFilter"
          >
            搜索
          </el-button>
        </div>
        <div>
          <el-button
              v-show="$hasPermission('statementCreate')"
              size="small"
              type="primary"
              icon="el-icon-plus"
              @click="addOrUpdateHandle()"
          >
            新增
          </el-button>
        </div>
      </div>
      <div class="table-wrap">
        <el-table
            v-loading="dataListLoading"
            fit
            border
            highlight-current-row
            :data="dataList"
            stripe
            style="width: 100%"
        >
          <el-table-column
              prop="title"
              :show-overflow-tooltip="true"
              label="名称"
          />
          <el-table-column
              prop="productNames"
              :show-overflow-tooltip="true"
              label="涉及业务"
          />
          <el-table-column
              label="最新访问地址"
              :show-overflow-tooltip="true"
              align="center"
          >
            <template v-slot="scope">
              <el-link :href="serverUrl+'/statement/current/'+scope.row.accessCode" target="_blank">
                {{scope.row.title+'最新版本'}}
              </el-link>
            </template>
          </el-table-column>
          <el-table-column
              label="上一版本访问地址"
              :show-overflow-tooltip="true"
          >
            <template v-slot="scope" >
              <el-link :href="serverUrl+'/statement/previous/'+scope.row.accessCode" target="_blank">{{scope.row.title+'上一个版本'}}</el-link>
            </template>
          </el-table-column>
          <el-table-column
              label="操作"
              width="140px"
          >
            <template v-slot="scope">
              <el-link
                  class="mr10"
                  :underline="false"
                  type="primary"
                  @click="addOrUpdateHandle(scope.row.id)"
              >
                <el-tooltip
                    v-show="$hasPermission('statementUpdate')"
                    content="修改"
                    placement="top"
                    effect="light"
                >
                  <i class="el-icon-edit" />
                </el-tooltip>
              </el-link>

              <el-link
                  class="mr10"
                  v-show="$hasPermission('statementDelete')"
                  :underline="false"
                  type="primary"
                  @click="deleteHandle(scope.row.id)"
              >
                <el-tooltip
                    content="删除"
                    placement="top"
                    effect="light"
                >
                  <i class="el-icon-delete" />
                </el-tooltip>
              </el-link>
              <el-link
                  class="mr10"
                  :underline="false"
                  type="primary"
                  @click="versionPage(scope.row)"
              >
                <el-tooltip
                    content="版本管理"
                    placement="top"
                    effect="light"
                >
                  <i class="el-icon-s-operation" />
                </el-tooltip>
              </el-link>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
            :current-page="dataForm.pageNo"
            :page-sizes="[10, 20, 50, 100]"
            :page-size="dataForm.pageSize"
            :total="total"
            layout="total, sizes, prev, pager, next, jumper"
            @size-change="pageSizeChangeHandle"
            @current-change="pageCurrentChangeHandle">
        </el-pagination>
        <!-- 弹窗, 新增 / 修改 -->
        <edit-dialog v-if="editVisible" ref="editDialog" @refreshDataList="getList"></edit-dialog>
      </div>
    </b-card>
  </div>
</template>

<script>
import PageIntroduce from '@core/components/pageIntroduce/PageIntroduce.vue'
import EditDialog from './index-edit.vue'

export default {
  components: {
    EditDialog, PageIntroduce,
  },
  data() {
    return {
      editVisible: false,
      dataListLoading: false,
      dataList: [],
      total: 0,
      dataForm: {
        order: '', // 排序，asc／desc
        orderField: '', // 排序，字段
        pageNo: 1, // 当前页码
        pageSize: 10, // 每页数
        searchStr: '',
        productIdList: [],
      },
      serverUrl: window.g.baseUrl,
    }
  },
  created() {
    this.getParams()
    this.getList()
  },
  methods: {
    getParams() {
      const { pageNo } = this.$route.query
      const { pageSize } = this.$route.query
      if (pageNo !== undefined && pageNo !== '') {
        this.dataForm.pageNo = Number(pageNo)
      }
      if (pageSize !== undefined && pageSize !== '') {
        this.dataForm.pageSize = Number(pageSize)
      }
    },
    getList() {
      this.dataListLoading = true
      this.$http.post('/privacy/statement/page', this.dataForm).then(({ data: res }) => {
        this.dataListLoading = false
        if (res.code !== 0) {
          this.dataList = []
          this.total = 0
          this.$message.error(res.msg)
        } else {
          this.dataList = res.data.list
          this.total = res.data.total
        }
      }).catch(() => {
        this.dataListLoading = false
      })
    },
    handlePage(data) {
      this.dataForm.pageNo = data.page
      this.getList()
    },
    addOrUpdateHandle(id) {
      this.editVisible = true
      this.$nextTick(() => {
        this.$refs.editDialog.init(id)
      })
    },
    handleFilter() {
      this.dataForm.pageNo = 1
      this.getList()
    },
    deleteHandle(id) {
      this.$confirm('确定进行删除操作?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        this.$http.delete(`/privacy/statement/delete?id=${id}`).then(({ data: res }) => {
          if (res.code !== 0) {
            this.$message.error(res.msg)
          } else {
            this.$message({
              message: '操作成功',
              type: 'success',
              duration: 500,
              onClose: () => {
                this.getList()
              },
            })
          }
        }).catch(() => {
        })
      }).catch(() => {
      })
    },
    versionPage(row) {
      this.$router.push({
        path: '/statement-version',
        query: {
          statementId: row.id,
          pageNo: this.dataForm.pageNo,
          pageSize: this.dataForm.pageSize,
          productNames: row.productNames,
          title: row.title,
        },
      })
    },
    pageSizeChangeHandle(val) {
      this.dataForm.pageNo = 1
      this.dataForm.pageSize = val
      this.getList()
    },
    pageCurrentChangeHandle(val) {
      this.dataForm.pageNo = val
      this.getList()
    },
  },
}
</script>

<style>
.el-tooltip__popper{
  max-width: 20%;
}
</style>
