<template>
  <el-dialog
      :title="dataForm.id ? '编辑' : '新增'"
      :visible.sync="dialogVisible"
      width="620px"
      @close="dialogClose"
  >
    <el-form
        v-if="dialogVisible"
        ref="form"
        v-loading="loading"
        :model="dataForm"
        label-width="120px"
        :rules="dataRule"
    >
      <el-form-item
          label="标题"
          prop="title">
        <el-input v-model="dataForm.title" placeholder="标题"></el-input>
      </el-form-item>
      <el-form-item
          label="业务"
          prop="productIdList"
      >
        <el-select v-model="dataForm.productIdList" multiple>
          <el-option v-for="(item,index) in productList" :key="index" :label="item.label"
                     :value="item.id"></el-option>
        </el-select>
      </el-form-item>

    </el-form>
    <div
        slot="footer"
        class="dialog-footer"
    >
      <el-button @click="dialogVisible = false">
        取 消
      </el-button>
      <el-button
          type="primary"
          :disabled="loading"
          @click="submitForm"
      >
        提 交
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
import { queryProductSelect } from '@/api/enterprise/product'
import debounce from 'lodash/debounce'

export default {
  data() {
    return {
      users: [],
      loading: false,
      dialogVisible: false,
      dataForm: {
        id: 0,
        title: '',
        productIdList: [],
      },
      productList: [],
    }
  },
  computed: {
    dataRule() {
      return {
        title: [
          { required: true, message: '标题不能为空。', trigger: 'blur' },
          {
            min: 3, max: 30, message: '标题长度在3到10个字符', trigger: 'blur',
          },
        ],
        productIdList: [
          {
            type: 'array', required: true, message: '请至少选择一个业务。', trigger: 'change',
          },
        ],
      }
    },
  },
  created() {
    this.queryProductSelect()
  },
  dialogClose() {
    this.$refs.form.resetFields()
  },
  methods: {
    init(id) {
      this.dialogVisible = true
      if (id) {
        this.getInfo(id)
      }
    },
    // 获取信息
    getInfo(id) {
      this.$http.get(`/privacy/statement/info?id=${id}`).then(({ data: res }) => {
        if (res.code !== 0) {
          this.$message.error(res.msg)
        } else {
          this.dataForm = {
            ...this.dataForm,
            ...res.data,
          }
        }
      }).catch(() => {})
    },
    queryProductSelect() {
      queryProductSelect().then(res => {
        const resData = res.data
        if (resData.code === 0) {
          this.productList = resData.data
        }
      })
    },
    dialogClose() {
      this.dataForm = {
        id: 0,
        title: '',
        productIdList: [],
      }
    },
    submitForm: debounce(function () {
      this.loading = true
      this.$refs.form.validate(valid => {
        if (valid) {
          this.$http.post(!this.dataForm.id ? '/privacy/statement/create' : '/privacy/statement/update', this.dataForm).then(({ data: res }) => {
            if (res.code !== 0) {
              this.$message.error(res.msg)
            } else {
              this.$message({
                message: '操作成功',
                type: 'success',
                duration: 500,
                onClose: () => {
                  this.dialogVisible = false
                  this.loading = false
                  this.$emit('refreshDataList')
                },
              })
            }
          }).catch(() => {
            this.loading = false
          })
        }
        this.loading = false
      })
    }, 1000, { leading: true, trailing: false }),
  },
}
</script>
